import React, { useEffect, useState } from "react"
import HomeObject from "../components/HomeObject"

export default function MobileInfiniteDiv({ content, column }) {
  let columnDiv
  let origHeight
  let c
  const [addLast, setAddLast] = useState(false)

  useEffect(() => {
    columnDiv = document.getElementById(column)

    setTimeout(function () {
      origHeight = columnDiv.scrollHeight
      setAddLast(true)
    }, 500)
  }, [])

  const watchScroll = () => {
    let scrollWindowPos = Math.ceil(columnDiv.scrollTop)
    if (scrollWindowPos > origHeight) {
      columnDiv.scrollTop = 0
    }
    if (scrollWindowPos <= 0) {
      columnDiv.scrollTop = origHeight
    }
  }

  useEffect(() => {
    columnDiv.addEventListener("scroll", watchScroll)
    return () => {
      columnDiv.removeEventListener("scroll", watchScroll)
    }
  }, [columnDiv, origHeight])

  return (
    <div
      id={column}
      className="scroll-box no-bar flex flex-col overflow-y-scroll"
    >
      {content.map((object, i) => {
        return (
          <HomeObject
            key={i}
            title={object.node.objectName}
            featuredImage={object.node.gallery[0]}
            link={object.node.path}
          />
        )
      })}
      {addLast ? (
        <>
          <HomeObject
            title={content[0].node.objectName}
            featuredImage={content[0].node.gallery[0]}
            link={content[0].node.path}
          />
          <HomeObject
            title={content[1].node.objectName}
            featuredImage={content[1].node.gallery[0]}
            link={content[1].node.path}
          />
        </>
      ) : null}
    </div>
  )
}
