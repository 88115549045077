import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Cursor from "../images/MORE.svg"

export default function HomeObject({ title, featuredImage, link }) {
  const styles = {
    cursor: `url(${Cursor}) 25 15, pointer`,
  }
  return (
    <Link className={"home-object cursor-none"} to={"/" + link}>
      <div className="grid grid-cols-1 grid-rows-1">
        <div className="col-start-1 row-start-1 p-4 z-40 w-full">
          <h1 className="sticky top-4 uppercase">{title}</h1>
        </div>
        <div className="z-30 col-start-1 row-start-1">
          <Img
            fluid={featuredImage.fluid}
            imgStyle={{ objectFit: "contain", objectPosition: "center center" }}
            style={{ width: "100%" }}
            loading="eager"
          />
        </div>
      </div>
    </Link>
  )
}
