import React from "react"

const More = () => {
  return (
    <svg
      width="49"
      height="16"
      viewBox="0 0 49 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.34483 15.2926V1.44089L3.84236 15.2926H5.57143L8.06897 1.44089V15.2926H9.41379V0.307389H6.9931L4.7069 14.1591L2.42069 0.307389H0V15.2926H1.34483ZM17.8286 15.6C21.0177 15.6 23.1118 12.4877 23.1118 7.72315C23.1118 3.05468 21.0177 0 17.8286 0C14.6394 0 12.5453 3.05468 12.5453 7.72315C12.5453 12.4877 14.6394 15.6 17.8286 15.6ZM17.8286 14.3512C15.5039 14.3512 13.8901 11.7 13.8901 7.62709C13.8901 3.76552 15.5039 1.24877 17.8286 1.24877C20.1532 1.24877 21.767 3.76552 21.767 7.62709C21.767 11.7 20.1532 14.3512 17.8286 14.3512ZM27.4729 15.2926V8.76059H30.3931C32.8906 8.76059 33.4862 9.1064 33.7552 11.3734C33.9473 12.968 33.7744 14.3704 34.197 15.2926H35.5419V15.1773C35.0808 14.1207 35.3498 12.9488 35.0424 10.7778C34.8118 9.14483 34.1778 8.43399 32.9099 8.1266C34.3892 7.64631 35.3498 6.20542 35.3498 4.53399C35.3498 1.88276 33.7167 0.307389 30.3931 0.307389H26.1281V15.2926H27.4729ZM30.3163 7.51182H27.4729V1.55616H30.2202C32.833 1.55616 34.0049 2.67044 34.0049 4.53399C34.0049 6.39754 32.8714 7.51182 30.3163 7.51182ZM48.6059 15.2926V14.0438H40.5369V8.1266H47.8374V6.87783H40.5369V1.55616H48.5099V0.307389H39.1921V15.2926H48.6059Z"
        fill="#231F20"
      />
    </svg>
  )
}

export default More
