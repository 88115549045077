import React, { useEffect, useState } from "react"
import HomeObject from "../components/HomeObject"

export default function InfiniteDiv({ content, column }) {
  let columnDiv
  let origHeight
  let main
  let mainHeight
  let firstHeight
  let c
  const [addLast, setAddLast] = useState(false)

  useEffect(() => {
    columnDiv = document.getElementById(column)
    main = document.getElementById("main")
    mainHeight = main.scrollHeight
    origHeight = columnDiv.scrollHeight
    firstHeight = columnDiv.firstChild.scrollHeight
    if (firstHeight >= mainHeight) {
      columnDiv.scrollTop = firstHeight - mainHeight + 58
    }
    setTimeout(function () {
      setAddLast(true)
    }, 500)
  }, [])

  const watchScroll = () => {
    let scrollWindowPos = columnDiv.scrollTop
    if (scrollWindowPos > origHeight) {
      columnDiv.scrollTop = 1
    }
    if (scrollWindowPos <= 0) {
      columnDiv.scrollTop = origHeight
    }
  }

  useEffect(() => {
    columnDiv.addEventListener("scroll", watchScroll)
    return () => {
      columnDiv.removeEventListener("scroll", watchScroll)
    }
  }, [columnDiv, origHeight])

  return (
    <div
      id={column}
      className="scroll-box no-bar flex flex-col overflow-y-scroll"
    >
      {content.map((object, i) => {
        return (
          <HomeObject
            key={i}
            title={object.node.objectName}
            featuredImage={object.node.gallery[0]}
            link={object.node.path}
          />
        )
      })}
      {addLast ? (
        <>
          <HomeObject
            title={content[0].node.objectName}
            featuredImage={content[0].node.gallery[0]}
            link={content[0].node.path}
          />
          <HomeObject
            title={content[1].node.objectName}
            featuredImage={content[1].node.gallery[0]}
            link={content[1].node.path}
          />
        </>
      ) : null}
    </div>
  )
}
