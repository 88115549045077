import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Transition from "../components/Transition"
import MobileInfiniteDiv from "../components/MobileInfiniteDiv"
import InfiniteDiv from "../components/InfiniteDiv"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import More from "../components/svg/More"
import SEO from "../components/seo"

export default function Home({
  data: {
    allContentfulObject: { edges },
    allContentfulAbout,
  },
}) {
  const [closed, setClosed] = useState(false)
  const [closing, setClosing] = useState(false)
  const [hovering, setHovering] = useState(false)
  const [left, setLeft] = useState(0)
  const [right, setRight] = useState(0)

  let leftColumn = []
  let rightColumn = []
  const breakpoints = useBreakpoint()

  function isOdd(num) {
    return num % 2
  }

  const mouseHandlerEnter = () => {
    setHovering(true)
  }
  const mouseHandlerLeave = () => {
    setHovering(false)
  }

  const positionHandler = e => {
    setLeft(e.clientX)
    setRight(e.clientY)
  }

  useEffect(() => {
    document.addEventListener("mousemove", positionHandler)
    return () => {
      document.removeEventListener("mousemove", positionHandler)
    }
  }, [])

  let featured = edges.filter(feat => {
    return feat.node.featured === true
  })

  featured.sort((a, b) => {
    return (
      (a.node.homePageOrder === null) - (b.node.homePageOrder === null) ||
      +(a.node.homePageOrder > b.node.homePageOrder) ||
      -(a.node.homePageOrder < b.node.homePageOrder)
    )
  })

  featured.map((object, i) => {
    if (isOdd(i) === 1) {
      rightColumn.push(object)
    } else {
      leftColumn.push(object)
    }
  })

  // const styles = {
  //   cursor: `url(${Cursor}) 25 15, pointer`,
  // }

  const closeHandler = () => {
    setClosing(true)
    setTimeout(() => {
      setClosed(true)
      setClosing(false)
    }, 1000)
  }

  return (
    <Transition>
      <SEO />
      <Layout about={allContentfulAbout}>
        {breakpoints.md ? (
          <section
            // styles={styles}
            className="home-object no-bar grid grid-cols-1 h-full overflow-hidden"
          >
            <MobileInfiniteDiv content={featured} column="mob-column" />
          </section>
        ) : (
          <section
            // styles={styles}
            onMouseEnter={mouseHandlerEnter}
            onMouseLeave={mouseHandlerLeave}
            className="home-object cursor-none no-bar lg:grid lg:grid-cols-2 h-full overflow-y-scroll lg:overflow-hidden"
          >
            {hovering ? (
              <div
                className="fixed transform z-100 pointer-events-none "
                style={{ left: left + "px", top: right + "px" }}
              >
                <More />
              </div>
            ) : null}
            <InfiniteDiv content={leftColumn} column="left-column" />
            <InfiniteDiv content={rightColumn} column="right-column" />
            {/* <MobileInfiniteDiv content={leftColumn} column="left-column" />
            <MobileInfiniteDiv content={rightColumn} column="right-column" /> */}
          </section>
        )}
      </Layout>
    </Transition>
  )
}

export const pageQuery = graphql`
  query {
    allContentfulObject {
      edges {
        node {
          objectName
          year
          materials
          path
          gallery {
            fluid(quality: 50, maxWidth: 1200) {
              base64
              srcSet
              aspectRatio
            }
          }
          featured
          homePageOrder
        }
      }
    }
    allContentfulAbout {
      edges {
        node {
          aboutDescription {
            raw
          }
          pressLinks {
            linkTitle
            link
          }
          socialMediaLinks {
            linkTitle
            link
          }
        }
      }
    }
    allContentfulHomePageVideo {
      edges {
        node {
          video {
            title
            file {
              url
              contentType
              fileName
            }
          }
        }
      }
    }
  }
`
